/* eslint-disable import/no-mutable-exports */
import { replayCanvasIntegration, replayIntegration, init } from "@sentry/react";
import { AxiosInstance } from "axios";
import { getEnvironment } from "./environment/environment-helpers";
import { ObservabilityAndMonitoringConfig } from "./initialise-observability-and-monitoring-types";

declare const VERSION: string;

export let sessionReplayIntegration: ReturnType<typeof replayIntegration> | null = null;

export let canvasReplayIntegration: ReturnType<typeof replayCanvasIntegration> | null = null;

export let usageMetricsEnabled = false;

export let usageApi: AxiosInstance | null = null;

export function initialiseObservabilityAndMonitoring(config: ObservabilityAndMonitoringConfig, appUrl: string) {
    const integrations = [];

    if (config.replayEnabled) {
        sessionReplayIntegration = replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            block: ["[type='password']"],
        });
        canvasReplayIntegration = replayCanvasIntegration();

        integrations.push(sessionReplayIntegration, canvasReplayIntegration);
    }

    if (config.errorTrackingEnabled) {
        init({
            dsn: "https://714bc62603359a8e3ec14212ac77db77@o4507453584637952.ingest.de.sentry.io/4507453594533968",
            integrations,
            // Only capture 10% of sessions for replay
            replaysSessionSampleRate: 0.1,
            // Capture all sessions which have an error
            replaysOnErrorSampleRate: 1.0,
            environment: getEnvironment(appUrl),
            release: VERSION,
        });
    }

    usageMetricsEnabled = config.usageMetricsEnabled;
}

export function setUsageApi(_usageApi: AxiosInstance) {
    usageApi = _usageApi;
}
